import React, { useState, useEffect, useMemo } from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  Stack,
  Table,
  Center,
  TextInput,
} from "@mantine/core";

import * as helpers from "./../common/helpers/index.js";
import { useDispatch, useSelector } from "react-redux";
import { watchOrders } from "../common/data/orders/redux";
import {
  IconAlarmMinus,
  IconAlarmPlus,
  IconAlertCircle,
  IconCopy,
} from "@tabler/icons-react";
import axios from "axios";

export default function Mtproto() {
  const [testData, setTestData] = useState(null);
  const [code, setCode] = useState(null);
  const [error, setError] = useState(null);

  const testRequest = async () => {
    try {
      const result = await axios("/api/mtproto-test-request");
      console.log(result);
      setTestData(result?.data);
    } catch (e) {
      console.log(e);
      setError(e?.response?.data);
    }
  };

  const deleteSession = async () => {
    try {
      const result = await axios("/api/mtproto-delete-session");
    } catch (e) {
      console.log(e);
      setError(e?.response?.data);
    }
  };

  const sendCode = async () => {
    try {
      const result = await axios("/api/mtproto-send-code");
    } catch (e) {
      console.log(e);
      setError(e?.response?.data);
    }
  };

  const verifyCode = async () => {
    try {
      const result = await axios({
        method: "post",
        url: "/api/mtproto-verify-code",
        data: { code: code },
      });
    } catch (e) {
      console.log(e);
      setError(e?.response?.data);
    }
  };

  return (
    <Container>
      <Center>
        <Box miw={250} mx="auto">
          <Stack>
            <Button
              color="red"
              maw="300px"
              onClick={() => setTestData(null)}
              loading={false}
            >
              Clear test data
            </Button>
            <Button
              color="blue"
              maw="300px"
              onClick={testRequest}
              loading={false}
            >
              Test request
            </Button>
            <Button
              color="blue"
              maw="300px"
              onClick={deleteSession}
              loading={false}
            >
              1. Delete session
            </Button>
            <Button color="blue" maw="300px" onClick={sendCode} loading={false}>
              2. Send code
            </Button>
            <TextInput value={code} onChange={(e) => setCode(e.target.value)} />
            <Button
              color="blue"
              maw="300px"
              onClick={verifyCode}
              loading={false}
            >
              3. Verify code
            </Button>
            <Box>
              <Center>{JSON.stringify(error)}</Center>
            </Box>
            <Box>
              <Center>{JSON.stringify(testData)}</Center>
            </Box>
          </Stack>
        </Box>
      </Center>
    </Container>
  );
}
